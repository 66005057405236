import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  TextField,
  useTheme,
  MenuItem,
  FormControlLabel,
  Switch,
  Typography
} from '@material-ui/core';
import FieldContainer from './FieldContainer';
import {
  AtcList,
  SingleDatePicker,
  TinTextField,
  ContactNumberTextField,
  DebounceTextField,
  Field,
  SrchSub,
  useConfirmDialog,
  SelectWithSearch
} from 'src/components';
import { PRICE_TYPES } from 'src/constants';
import SearchData from './DataProccessor'
import data from './addresses.json';

const DEFAULT_TYPES_VALUE = {
  str: '',
  int: 0,
  json: {},
  boolean: false,
  date: ''
};

const SubAccForm = ({
  subTypeMeta = {},
  subAcc = {},
  currentSubVal = {},
  showAdvance = false,
  error = {},
  updateField,
  updateKVSField,
  updateSubParent,
  updateSubLink,
  addAtc,
  removeAtc,
  showContainer = true,
  hasChanges,
  showDeactivateToggle = false
}) => {
  const theme = useTheme();
  const { prompt } = useConfirmDialog();
  const { pretty_flds = {}, req_flds = [], kvs = {} } = subTypeMeta;
  const flds = useMemo(() => {
    const meta_flds = subTypeMeta?.flds ?? [];

    if (Boolean(subTypeMeta?.ui_layout?.show_price_type))
      return [...meta_flds, 'invPriceType'];

    return meta_flds;
  }, [subTypeMeta]);

  const fldAllowed = fld => flds.includes(fld);
  const fldRequired = fld => req_flds.includes(fld);

  const address_ui_mode = subTypeMeta?.ui_layout?.address_ui_mode;

  const [addressData, setAddressData] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedTownCity, setSelectedTownCity] = useState('');
  const [selectedBarangay, setSelectedBarangay] = useState('');


  useEffect(() => {
    if (address_ui_mode) {
      const loadData = () => {
        const localStorageData = localStorage.getItem('addressData');
        if (localStorageData) {
          setAddressData(JSON.parse(localStorageData));
        } else {
          setAddressData(data);
          localStorage.setItem('addressData', JSON.stringify(data));
        }
      };
      loadData();
    }
  }, [address_ui_mode]);

  const provinces = [...new Set(addressData.map(item => item.stateProv))];
  const townCities = selectedProvince
    ? [
            ...new Set(
          addressData
            .filter(item => item.stateProv === selectedProvince)
            .map(item => item.cityMuni)
        )
      ]
    : [];

  console.log("townCities", townCities);

  const barangays = selectedTownCity
    ? [
        ...new Set(
          addressData
            .filter(
              item =>
                item.stateProv === selectedProvince &&
                item.cityMuni === selectedTownCity
            )
            .map(item => item.townBar)
        )
      ]
    : [];

  useEffect(() => {
    if (subAcc?.addressProvince) {
      setSelectedProvince(subAcc?.addressProvince);
    }
  }, [subAcc?.addressProvince]);

  useEffect(() => {
    if (subAcc?.addressCity) {
      setSelectedTownCity(subAcc?.addressCity);
    }
  }, [subAcc?.addressCity]);

  useEffect(() => {
    if (subAcc?.addressBrgy) {
      setSelectedBarangay(subAcc?.addressBrgy);
    }
  }, [subAcc?.addressBrgy]);

  function parseValue(type, value) {
    switch (type) {
      case 'boolean':
        if (typeof value === 'string') return value === '1';
        else return value;
      default:
        return value;
    }
  }

  function getKeyValue(key) {
    const keyValues = subAcc?.kvs || [];
    const savedKvs = keyValues.find(data => data.key === key);

    const defaultValue =
      kvs?.[key]?.defaultValue || DEFAULT_TYPES_VALUE?.[kvs[key].type];

    // if sub is not new, return the existing value
    if (savedKvs?.value !== '' || subAcc?.ixSub)
      return parseValue(kvs[key].type, savedKvs?.value);
    // return the default value
    else return defaultValue;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={theme.spacing(showContainer ? 4 : 2)}
    >
      {/* ID */}
      {Boolean(subAcc?.ixSub) && (
        <FieldContainer showContainer={showContainer} title="ID">
          <Box display="flex" justifyContent="space-between">
            <TextField
              fullWidth
              variant="outlined"
              disabled
              value={subAcc.ixSub}
            />

            {showDeactivateToggle && (
              <FormControlLabel
                control={
                  <Switch
                    checked={subAcc?.subStatus === 1}
                    onChange={({ target: { checked } }) => {
                      if (!hasChanges('subStatus', checked ? 1 : 0)) {
                        updateField({
                          target: {
                            name: 'subStatus',
                            value: checked ? 1 : 0
                          }
                        });
                        return;
                      }

                      prompt({
                        title: (
                          <Typography variant="h4">
                            {`Confirm  ${
                              checked ? 'Activation' : 'Deactivation'
                            }`}
                          </Typography>
                        ),
                        body: (
                          <Typography variant="body1">
                            Are you sure you want to{' '}
                            {checked ? 'activate' : 'deactivate'}{' '}
                            <strong> {subAcc.sSub} </strong>?
                          </Typography>
                        ),
                        onOk: () => {
                          updateField({
                            target: {
                              name: 'subStatus',
                              value: checked ? 1 : 0
                            }
                          });
                        },
                        okText: 'Confirm',
                        okProps: {
                          variant: 'contained',
                          color: 'primary'
                        },
                        cancelProps: {
                          color: 'primary'
                        }
                      });
                    }}
                  />
                }
                label="Active"
                labelPlacement="start"
              />
            )}
          </Box>
        </FieldContainer>
      )}

      {fldAllowed('RefNo') && (
        <FieldContainer
          showContainer={showContainer}
          title={pretty_flds?.RefNo || 'Ref No.'}
        >
          <DebounceTextField
            label={pretty_flds?.RefNo || 'Ref No.'}
            variant="outlined"
            fullWidth
            name="RefNo"
            error={Boolean(error?.RefNo)}
            helperText={error?.RefNo || ''}
            onChange={updateField}
            value={subAcc?.RefNo || ''}
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired('RefNo')}
          />
        </FieldContainer>
      )}

      {/* Title */}
      {(subTypeMeta?.acc_title === 1 || subTypeMeta?.acc_title === 2) && (
        <FieldContainer
          showContainer={showContainer}
          title={pretty_flds?.sSub || 'Title'}
        >
          <DebounceTextField
            variant="outlined"
            label={pretty_flds?.sSub || 'Title'}
            fullWidth
            name="sSub"
            value={subAcc?.sSub || ''}
            onChange={updateField}
            error={Boolean(error?.sSub)}
            helperText={error?.sSub || ''}
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired('sSub')}
          />
        </FieldContainer>
      )}

      {/* Name */}
      <FieldContainer showContainer={showContainer} title="Name">
        {fldAllowed('lname') && (
          <DebounceTextField
            label={pretty_flds?.lname || 'Last Name'}
            variant="outlined"
            fullWidth
            name="lname"
            error={Boolean(error?.lname)}
            helperText={error?.lname || ''}
            onChange={updateField}
            value={subAcc?.lname || ''}
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired('lname')}
          />
        )}

        {fldAllowed('fname') && (
          <DebounceTextField
            label={pretty_flds?.fname || 'First Name'}
            variant="outlined"
            fullWidth
            name="fname"
            error={Boolean(error?.fname)}
            helperText={error?.fname || ''}
            onChange={updateField}
            value={subAcc?.fname || ''}
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired('fname')}
          />
        )}

        {fldAllowed('mname') && (
          <DebounceTextField
            label={pretty_flds?.mname || 'First Name'}
            variant="outlined"
            fullWidth
            name="mname"
            error={Boolean(error?.mname)}
            helperText={error?.mname || ''}
            onChange={updateField}
            value={subAcc?.mname || ''}
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired('mname')}
          />
        )}
        {(fldAllowed('lname') || fldAllowed('fname')) && (
          <Box display="flex" justifyContent="space-between" gridGap="1em">
            <DebounceTextField
              label="Prefix Name"
              variant="outlined"
              fullWidth
              name="pname"
              error={Boolean(error?.pname)}
              helperText={error?.pname || ''}
              onChange={updateField}
              value={subAcc?.pname || ''}
              InputLabelProps={{
                shrink: true
              }}
            />
            <DebounceTextField
              label="Suffix Name"
              variant="outlined"
              fullWidth
              name="sname"
              error={Boolean(error?.sname)}
              helperText={error?.sname || ''}
              onChange={updateField}
              value={subAcc?.sname || ''}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>
        )}
      </FieldContainer>

      {fldAllowed('taxRegName') && (
        <FieldContainer
          showContainer={showContainer}
          title={pretty_flds?.taxRegName || 'Registered Name'}
        >
          <DebounceTextField
            fullWidth
            variant="outlined"
            label={pretty_flds?.taxRegName || 'Registered Name'}
            onChange={updateField}
            error={Boolean(error?.taxRegName)}
            helperText={error?.taxRegName || ''}
            value={subAcc?.taxRegName || ''}
            name="taxRegName"
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired('taxRegName')}
          />
        </FieldContainer>
      )}

      {fldAllowed('birthDate') && (
        <FieldContainer
          showContainer={showContainer}
          title={pretty_flds?.birthDate || 'Birthday'}
        >
          <SingleDatePicker
            fullWidth
            onChange={updateField}
            error={Boolean(error?.birthDate)}
            helperText={error?.birthDate || ''}
            value={subAcc?.birthDate || ''}
            name="birthDate"
            label={pretty_flds?.birthDate || 'Birthday'}
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired('birthDate')}
          />
        </FieldContainer>
      )}

      <FieldContainer showContainer={showContainer} title="Contacts">
        {fldAllowed('contactNo') && (
          <ContactNumberTextField
            variant="outlined"
            label={pretty_flds?.contactNo || 'Phone No.'}
            fullWidth
            onChange={updateField}
            value={subAcc?.contactNo || ''}
            name="contactNo"
            error={Boolean(error?.contactNo)}
            helperText={error?.contactNo || ''}
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired('contactNo')}
          />
        )}

        {fldAllowed('email') && (
          <DebounceTextField
            type="email"
            label={pretty_flds?.email || 'E-mail'}
            variant="outlined"
            fullWidth
            error={Boolean(error?.email)}
            helperText={error?.email || ''}
            onChange={updateField}
            value={subAcc?.email || ''}
            name="email"
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired('email')}
          />
        )}
      </FieldContainer>

      <FieldContainer showContainer={showContainer} title="Address">
        {fldAllowed('address') && (
          <>
            <DebounceTextField
              variant="outlined"
              label={pretty_flds?.address || 'Address'}
              fullWidth
              onChange={updateField}
              value={subAcc?.address || ''}
              name="address"
              error={Boolean(error?.address)}
              helperText={error?.address || ''}
              InputLabelProps={{
                shrink: true
              }}
              required={fldRequired('address')}
            ></DebounceTextField>
          </>
        )}

        {fldAllowed('address2') && (
          <DebounceTextField
            variant="outlined"
            label={pretty_flds?.address2 || 'Address 2'}
            fullWidth
            onChange={updateField}
            value={subAcc?.address2 || ''}
            name="address2"
            error={Boolean(error?.address2)}
            helperText={error?.address2 || ''}
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired('address2')}
          ></DebounceTextField>
        )}
      </FieldContainer>

      <FieldContainer showContainer={showContainer} title="Detailed Address">

        {fldAllowed('addressProvince') && (
          <Box display="flex" alignItems="center">
            {address_ui_mode !== 2 ? (
              <DebounceTextField
                label={pretty_flds?.addressProvince || 'Province'}
                variant="outlined"
                fullWidth
                onChange={e => {
                  updateField(e);
                  setSelectedProvince(e.target.value);
                }}
                value={subAcc?.addressProvince || selectedProvince || ''}
                name="addressProvince"
                error={Boolean(error?.addressProvince)}
                helperText={error?.addressProvince || ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            ) : (
              <Box style={{ width: '100%' }}>
                <SelectWithSearch
                  label={pretty_flds?.addressProvince || 'Province'}
                  options={provinces.map(province => ({
                    value: province,
                    description: province
                  }))}
                  searchKeys={['description']}
                  onChange={e => {
                    const newValue = e.target.value;
                    setSelectedProvince(newValue);
                    updateField({
                      target: { name: 'addressProvince', value: newValue }
                    });
                  }}
                  value={selectedProvince || ''}
                  name="addressProvince"
                  error={Boolean(error?.addressProvince)}
                  helperText={error?.addressProvince || ''}
                />
              </Box>
            )}
          </Box>
        )}

        {fldAllowed('addressCity') && (
          <Box display="flex" alignItems="center">
            {address_ui_mode !== 2 ? (
              <DebounceTextField
                label={pretty_flds?.addressCity || 'City'}
                variant="outlined"
                fullWidth
                onChange={e => {
                  updateField(e);
                  setSelectedTownCity(e.target.value);
                }}
                value={subAcc?.addressCity || selectedTownCity || ''}
                name="addressCity"
                error={Boolean(error?.addressCity)}
                helperText={error?.addressCity || ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            ) : (
              <Box style={{ width: '100%' }}>
                <SelectWithSearch
                  label={pretty_flds?.addressCity || 'City'}
                  options={townCities.map(townCity => ({
                    value: townCity,
                    description: townCity
                  }))}
                  searchKeys={['description']}
                  onChange={e => {
                    const newValue = e.target.value;
                    setSelectedTownCity(newValue);
                    updateField({
                      target: { name: 'addressCity', value: newValue }
                    });
                  }}
                  value={selectedTownCity || ''}
                  name="addressCity"
                  error={Boolean(error?.addressCity)}
                  helperText={error?.addressCity || ''}
                />
              </Box>
            )}
          </Box>
        )}

        {fldAllowed('addressBrgy') && (
          <Box display="flex" alignItems="center">
            {address_ui_mode !== 2 ? (
              <DebounceTextField
                label={pretty_flds?.addressBrgy || 'Barangay'}
                variant="outlined"
                fullWidth
                onChange={e => {
                  updateField(e);
                  setSelectedBarangay(e.target.value);
                }}
                value={subAcc?.addressBrgy || selectedBarangay || ''}
                name="addressBrgy"
                error={Boolean(error?.addressBrgy)}
                helperText={error?.addressBrgy || ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            ) : (
              <Box style={{ width: '100%' }}>
                <SelectWithSearch
                  label={pretty_flds?.addressBrgy || 'Barangay'}
                  options={barangays.map(barangay => ({
                    value: barangay,
                    description: barangay
                  }))}
                  searchKeys={['description']}
                  onChange={e => {
                    const newValue = e.target.value;
                    setSelectedBarangay(newValue);
                    updateField({
                      target: { name: 'addressBrgy', value: newValue }
                    });
                  }}
                  value={selectedBarangay || ''}
                  name="addressBrgy"
                  error={Boolean(error?.addressBrgy)}
                  helperText={error?.addressBrgy || ''}
                />
              </Box>
            )}
          </Box>
        )}

        {/* Street Field */}
        {fldAllowed('addressStreet') && (
          <DebounceTextField
            label={pretty_flds?.addressStreet || 'Street'}
            variant="outlined"
            fullWidth
            onChange={updateField}
            value={subAcc?.addressStreet || ''}
            name="addressStreet"
            error={Boolean(error?.addressStreet)}
            helperText={error?.addressStreet || ''}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}

        {/* ZIP Field */}
        {fldAllowed('addressZIP') && (
          <DebounceTextField
            label={pretty_flds?.addressZIP || 'ZIP'}
            variant="outlined"
            fullWidth
            onChange={updateField}
            value={subAcc?.addressZIP || ''}
            name="addressZIP"
            error={Boolean(error?.addressZIP)}
            helperText={error?.addressZIP || ''}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}

      </FieldContainer>

      <FieldContainer showContainer={showContainer} title="Linked Sub-Accounts">
        {Boolean(subTypeMeta?.parent?.ixSubType) && (
          <SrchSub
            ixSubType={subTypeMeta?.parent?.ixSubType}
            sSubType={`${subTypeMeta?.parent?.sSubType} (Parent)`}
            selectedSub={subAcc?.sSubParent || ''}
            handleSubSelectRoot={updateSubParent}
            error={Boolean(error?.ixSubParent)}
            errorMessage={error?.ixSubParent || ''}
            required={fldRequired('ixSubParent')}
          />
        )}
        {Object.entries(subTypeMeta?.subLink || {}).map(
          ([key, { ixSubType, ...rest }]) => {
            if (ixSubType === 0) return null;

            return (
              <SrchSub
                key={'ixSubLink' + key}
                ixSubType={ixSubType}
                selectedSub={subAcc?.['sSubLink' + key] || ''}
                handleSubSelectRoot={({ ixSub, sSub }) =>
                  updateSubLink('ixSubLink' + key, 'sSubLink' + key, {
                    ixSub,
                    sSub
                  })
                }
                error={Boolean(error['ixSubLink' + key])}
                errorMessage={error?.['ixSubLink' + key] || ''}
                required={fldRequired('ixSubLink' + key)}
                {...rest}
              />
            );
          }
        )}
      </FieldContainer>

      {fldAllowed('TIN') && (
        <FieldContainer
          showContainer={showContainer}
          title={pretty_flds?.TIN || 'TIN'}
        >
          <TinTextField
            variant="outlined"
            fullWidth
            onChange={updateField}
            value={subAcc?.TIN || ''}
            name="TIN"
            label="TIN"
            error={Boolean(error?.TIN)}
            helperText={error?.TIN || ''}
            InputLabelProps={{
              shrink: true
            }}
            required={fldRequired(`TIN`)}
          />
        </FieldContainer>
      )}

      {fldAllowed('customerType') && (
        <FieldContainer showContainer={showContainer} title="Customer Type">
          <TextField
            variant="outlined"
            fullWidth
            onChange={updateField}
            value={subAcc?.customerType || 0}
            name="customerType"
            error={Boolean(error?.customerType)}
            helperText={error?.customerType || ''}
            InputLabelProps={{
              shrink: true
            }}
            select
          >
            {Object.entries(subTypeMeta?.customerType_choices).map(
              ([key, value]) => (
                <MenuItem value={key} key={key}>
                  {value}
                </MenuItem>
              )
            )}
          </TextField>
        </FieldContainer>
      )}

      {fldAllowed('vatType') && (
        <FieldContainer showContainer={showContainer} title="VAT type">
          <TextField
            label=""
            variant="outlined"
            fullWidth
            onChange={updateField}
            value={
              subAcc?.vatType === '' ||
              subAcc?.vatType === undefined ||
              subAcc?.vatType === null
                ? 1
                : subAcc.vatType
            }
            name="vatType"
            error={Boolean(error?.vatType)}
            helperText={error?.vatType || ''}
            InputLabelProps={{
              shrink: true
            }}
            select
          >
            {Object.entries(subTypeMeta?.vatType_choices).map(
              ([key, value]) => (
                <MenuItem value={parseInt(key)} key={key}>
                  {value}
                </MenuItem>
              )
            )}
          </TextField>
        </FieldContainer>
      )}

      {fldAllowed('invPriceType') && (
        <FieldContainer showContainer={showContainer} title="Price Type">
          <TextField
            variant="outlined"
            fullWidth
            onChange={updateField}
            value={subAcc?.invPriceType || 0}
            name="invPriceType"
            error={Boolean(error?.invPriceType)}
            helperText={error?.invPriceType || ''}
            InputLabelProps={{
              shrink: true
            }}
            select
          >
            {PRICE_TYPES.map(({ caption, value }) => (
              <MenuItem value={value} key={value}>
                {caption}
              </MenuItem>
            ))}
          </TextField>
        </FieldContainer>
      )}

      {fldAllowed('atc_list') && (
        <FieldContainer showContainer={showContainer} title="Default ATC">
          <AtcList
            selectedList={subAcc?.atc_list || []}
            onAddAtc={addAtc}
            onRemoveAtc={removeAtc}
          />
        </FieldContainer>
      )}

      {Object.entries(kvs)
        .filter(([, props]) => !props?.hidden)
        .sort((a, b) => (a[1]?.sort || 0) - (b[1]?.sort || 0))
        .map(([key, props]) => {
          const data = {
            ...props,
            value: getKeyValue(key),
            fld: key,
            error: Boolean(error?.kvs?.[key] || ''),
            errorMessage: error?.kvs?.[key] || ''
          };

          if (props?.ui_component === 'logs') {
            data.currentValue = currentSubVal;
          }

          return (
            <FieldContainer
              showContainer={showContainer}
              title={props?.caption || ''}
              key={key}
            >
              <Field data={data} onChangeField={updateKVSField} />
            </FieldContainer>
          );
        })}

      {fldAllowed('rate') && (
        <FieldContainer showContainer={showContainer} title={'Rate'}>
          <Field
            data={{
              ui_component: 'number',
              value: subAcc?.rate || 0,
              fld: 'rate'
            }}
            onChangeField={updateField}
          />
        </FieldContainer>
      )}

      {showAdvance && (
        <>
          {fldAllowed('uuid') && (
            <FieldContainer
              showContainer={showContainer}
              title={pretty_flds?.UUID || 'UUID'}
            >
              <TextField
                fullWidth
                variant="outlined"
                label="UUID"
                onChange={updateField}
                value={subAcc?.uuid || ''}
                name="uuid"
                error={Boolean(error?.uuid)}
                helperText={error?.uuid || ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FieldContainer>
          )}
          {fldAllowed('lSort') && (
            <FieldContainer
              showContainer={showContainer}
              title={pretty_flds?.lSort || 'Order'}
            >
              <TextField
                fullWidth
                variant="outlined"
                label="Order"
                onChange={updateField}
                value={subAcc?.lSort || ''}
                name="lSort"
                error={Boolean(error?.lSort)}
                helperText={error?.lSort || ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FieldContainer>
          )}
        </>
      )}
    </Box>
  );
};

export default SubAccForm;
